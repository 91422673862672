<template>
  <header id="menu" class="header" :class="isChange ? 'active' : ''">
    <div class="header_wrap">
      <div class="nav_content">
        <h1>
          <router-link to="/">
            <img
              class="logo"
              src="../assets/homeImg/logo_white.png"
              alt="佐尚网络"
              title="佐尚网络"
            />
          </router-link>
        </h1>
        <div class="menu_icon" @click="showList">
          <i class="iconfont icon-caidan"></i>
        </div>
      </div>
      <ul class="nav_list" @click="onClick">
        <li class="nav" :class="active == 0 ? 'active' : ''">
          <!-- <span>官网首页</span> -->
          <router-link to="/">
            <div class="text_content">
              <span>首页</span>
            </div>
          </router-link>
        </li>
        <li class="nav" :class="active == 1 ? 'active' : ''">
          <!-- <span>集团介绍</span> -->
          <router-link to="/smartFactory">
            <div class="text_content">
              <span>智慧工厂</span>
            </div>
          </router-link>
        </li>
        <li class="nav" :class="active == 2 ? 'active' : ''">
          <!-- <span>老家礼物</span> -->
          <router-link to="/smartCity">
            <div class="text_content">
              <span>智慧城市</span>
            </div>
          </router-link>
        </li>
        <li class="nav" :class="active == 3 ? 'active' : ''">
          <!-- <span>媒体中心</span> -->
          <router-link to="/about">
            <!-- <span>媒体中心</span> -->
            <div class="text_content">
              <span>关于我们</span>
            </div>
          </router-link>
        </li>
        <li class="nav" :class="active == 4 ? 'active' : ''">
          <!-- <span>招商合作</span> -->
          <router-link to="/contactUs">
            <!-- <span>招商合作</span> -->
            <div class="text_content">
              <span>联系我们</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import $ from "jquery";
export default {
  name: "Header",
  props: {
    isChange: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 0,
      isShowList: false,
    };
  },
  // watch: {
  //   isChange(val) {
  //     console.log(val);
  //   },
  // },
  mounted() {
    console.log(this.isChange);
    let _this = this;
    this.ready = false;
    // setTimeout(function () {
    var name = _this.$route.name;
    console.log(name);
    if (name == "Home") {
      _this.active = 0;
    } else if (name == "SmartFactory") {
      _this.active = 1;
    } else if (name == "SmartCity") {
      _this.active = 2;
    } else if (name == "About") {
      _this.active = 3;
    } else if (name == "ContactUs") {
      _this.active = 4;
    }
    _this.ready = true;
    // }, 100);
    // this.getSeriesList();
  },
  // created() {
  //   console.log("created");
  //   var name = this.$route.name;
  //   console.log(name);
  // },
  methods: {
    onClick() {
      console.log("onClick");
      $(".nav_list").animate({
        top: -210,
      });
      this.isShowList = false;
    },
    showList() {
      if (this.isShowList) {
        $(".nav_list").animate({
          top: -210,
        });
        this.isShowList = false;
      } else {
        $(".nav_list").animate({
          top: 48,
        });
        this.isShowList = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  .header_wrap {
    width: 100%;
    position: relative;
    background: #fff;
    .nav_content {
      height: 48px;
      width: 100%;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 90px;
        height: 25px;
        // margin: 0 79px;
      }
      .menu_icon {
        i {
          font-size: 20px;
          color: #000;
          font-weight: bold;
        }
      }
    }
    .nav_list {
      background: #fff;
      position: absolute;
      top: -210px;
      right: 0;
      border-radius: 3px;
      width: 140px;
      padding: 0 15px;
      // border: 1px solid rgba(216, 216, 216, 0.75);
      box-shadow: 0px 4px 10px 1px rgba(175, 175, 175, 0.3);
      z-index: -1;
      .nav {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid rgba(216, 216, 216, 0.3);
        a {
          text-decoration: none;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
