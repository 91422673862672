<template>
  <div class="home">
    <Header :isChange="isChange"></Header>
    <div id="bannerImg" class="banner">
      <!-- <img src="../assets/homeImg/header.png" alt="" /> -->
      <img
        src="https://oss-gongsi.zuoshang2019.com/gongsi/header8.png"
        alt="郑州佐尚网络"
      />
    </div>
    <div class="smart_factory">
      <h2 class="title">智慧工厂解决方案</h2>
      <!-- <h4 class="subtitle">
        涵盖了能源管理、砂石骨料一卡通、设备管理、车辆调度等多个领域
      </h4> -->
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <h3 class="detail_title">能源管理</h3>
              <!-- <h4 class="detail_subtitle">Energy management system</h4>
              <h4 class="detail_list_title">能源管理解决方案</h4> -->
              <div class="case_img">
                <img src="../assets/homeImg/factory.png" alt="智慧工厂" />
              </div>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>电耗、煤耗、气耗、水耗等综合监测；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>针对工厂工艺各个环节，进行能耗评比；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>通过在线实时监测，反向诊断高能耗点位；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_3">
                    <div class="dot_small"></div>
                  </div>
                  <div>融入碳排放量计算，对整体能耗进行有效管理。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <button type="button">
          <router-link to="/smartFactory">更多解决方案</router-link>
        </button>
      </div>
    </div>
    <div class="smart_factory smart_city">
      <h2 class="title">智慧城市解决方案</h2>
      <!-- <h4 class="subtitle">涵盖了智慧停车场、智能监管、城市油烟监管等多个领域</h4> -->
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <h3 class="detail_title">智慧停车场</h3>
              <!-- <h4 class="detail_subtitle">Smart parking lot</h4>
              <h4 class="detail_list_title">智慧停车场解决方案</h4> -->
              <div class="case_img">
                <img src="../assets/homeImg/city.png" alt="智慧城市" />
              </div>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>自动升降道闸，减少排队时长；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>停车场系统自动扣费，减少人为干预，避免收费漏洞；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>精准车牌识别，识别率可达到99.9%，识别精准高效；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_3">
                    <div class="dot_small"></div>
                  </div>
                  <div>
                    支持微信、支付宝、ETC等多种支付方式,满足各种用户需求,提高
                    电子支付效率。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <button type="button">
          <router-link to="/smartCity">更多解决方案</router-link>
        </button>
      </div>
    </div>
    <div class="cooperative_partner">
      <div class="title">合作伙伴</div>
      <!-- <div class="subtitle">我们期待与您合作</div> -->
      <img src="../assets/homeImg/cooperative_partner.png" alt="" />
    </div>
    <div class="about">
      <div class="title">关于我们</div>
      <p class="text">
        郑州佐尚网络科技有限责任公司成立于2019年，秉承“诚信、专业、以客户为中心、持续创新”的精神，着力打造集“战略咨询、平台技术和专业服务“为一体的物联网科技公司。我们以物联网技术应用为基础，专注并服务于智慧城市的建设，以及智慧工厂的升级改造。
      </p>
      <p class="text">
        我们在城市信息化监管、智慧停车等领域有着丰富的技术服务经验，实现对传统管理模式的优化升级，助力城市的智能化改造。同时我们在工厂能源管理、车辆一卡通等领域拥有多种应用技术，真正帮助企业进行数字化转型。
      </p>
      <p class="text">
        我们以“提供最专业的技术服务，为客户创造最大价值”为使命，不断提升服务质量、提高技术水平。为城市以及企业的智能化发展提供便捷、优质的解决方案，我们坚持科技创新，立志成为中国互联网技术服务行业的佼佼者。
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import $ from "jquery";
// import "fullpage.js/dist/fullpage.min.css";

import Footer from "../components/footer";
import Header from "../components/header";
import { getBannerList, getProductList, getNewsList } from "../api/api";

export default {
  name: "Home",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      devActive: 0,
      swiper: null,
      mySwiper: null,
      enterActive: -1,
      leaveActive: -1,
      isChange: false,
    };
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        //参数说明
        //sign：公司渠道唯一标识，复制即可，无需改动
        //uid：用户唯一标识，如果没有则不填写，默认为空
        //data：用于传递用户信息，最多支持5个，参数名分别为c1,c2,c3,c4,c5；默认为空
        //selector：css选择器(document.querySelector, 如#btnid .chat-btn等)，用于替换默认的常驻客服入口
        //callback(type, data): 回调函数,type表示事件类型， data表示事件相关数据
        //type支持的类型：newmsg有新消息，error云智服页面发生错误， close聊天窗口关闭

        var stStart = $(window).scrollTop() + 74;
        var target = $("#bannerImg").height();
        if (stStart > target) {
          // $('#menu').addClass('active')
          self.isChange = true;
          // console.log(self.isChange);
        } else {
          // $('#menu').removeClass('active')
          self.isChange = false;
          // console.log(self.isChange);
        }
        console.log(target);
        $(window).scroll(function () {
          var st = $(window).scrollTop() + 74;

          if (st > target) {
            // $('#menu').addClass('active')
            self.isChange = true;
            // console.log(self.isChange);
          } else {
            // $('#menu').removeClass('active')
            self.isChange = false;
            // console.log(self.isChange);
          }
        });
      }, 100);
    });
  },
  beforeRouteLeave(to, from, next) {
    // if (this.username && this.password) {
    //   next();
    // } else {
    //   if (confirm("表单还没填完,你真的要离开吗?")) {
    //     next();
    //   } else {
    //     next(false);
    //   }
    // }
    window.onmousewheel = document.onmousewheel = null;
    next();
  },

  methods: {},
};
</script>
<style lang="scss"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.home {
  .smart_factory {
    padding: 30px 0;
    background: #fff;
    .title {
      font-size: 20px;
      color: #333333;
    }
    // .subtitle {
    //   font-size: 14px;
    //   color: #999999;
    //   margin-top: 22px;
    //   margin-bottom: 60px;
    // }
    .case {
      width: 100%;
      padding: 0 15px;
      // display: flex;
      // justify-content: space-between;
      // align-items: flex-start;
      .case_introduce {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .detail {
          text-align: left;
          .detail_title {
            text-align: center;
            font-size: 18px;
            color: #2f78ff;
            margin-top: 20px;
          }
          // .detail_subtitle {
          //   font-size: 20px;
          //   color: #0c243d;
          //   margin-top: 5px;
          //   margin-bottom: 15px;
          // }
          // .detail_list_title {
          //   font-size: 20px;
          //   color: #2f78ff;
          //   margin-bottom: 30px;
          // }
          .list {
            margin-top: 20px;
            .item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px;
              color: #333333;
              margin-bottom: 20px;
              div {
                text-align: justify;
              }
              .dot_big {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #1fdb84;
                margin-right: 5px;
                flex-shrink: 0;
                &.dot_big_1 {
                  background: #2f78ff;
                }
                &.dot_big_2 {
                  background: #f78921;
                }
                &.dot_big_3 {
                  background: #f72181;
                }
                .dot_small {
                  width: 4px;
                  height: 4px;
                  border-radius: 100%;
                  background: #fff;
                }
              }
            }
          }
        }
      }
      .case_img {
        width: 100%;
        margin-top: 16px;
        img {
          width: 100%;
        }
      }
    }
    .btn {
      margin-top: 40px;
      button {
        width: 170px;
        height: 40px;
        background: #2f78ff;
        border-radius: 6px 6px 6px 6px;
        border: none;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        a {
          font-size: 14px;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  .smart_city {
    background: #fafbfc;
    .btn {
      button {
        background: #fff;
        border: 1px solid #2f78ff;
        color: #2f78ff;
        a {
          color: #2f78ff;
        }
      }
    }
  }
  .cooperative_partner {
    background: #fff;
    padding: 30px 0;
    img {
      width: 100%;
    }
    .title {
      font-size: 20px;
      color: #333333;
      margin-bottom: 28px;
    }
  }
  .about {
    width: 100%;
    padding: 30px 15px;
    background-image: url("../assets/homeImg/about.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 20px;
      color: #fff;
      margin-bottom: 20px;
    }
    .text {
      font-size: 14px;
      color: #ffffff;
      line-height: 28px;
      text-align: justify;
      width: 100%;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
