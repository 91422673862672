<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-view></router-view> -->
    <keep-alive v-if="$route.name != 'Home'">
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
    <!-- <Footer></Footer> -->
    <a class="chat" target="_blank" href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d27c02c120449fed1db4b86ad35cf1936152b2797ddedb7850f98be4b8d5d953e5965cead13bfe5093f550aff150a47a">
      <img src="./assets/homeImg/chat.png" alt="" />
      <div>咨询</div>
    </a>
  </div>
</template>

<script>
import Header from "./components/header";

export default {
  name: "App",
  components: {
    // swiper,
    // swiperSlide,
    Header,
    // Footer,
  },
  mounted() {
    // window.yzf &&
    //   window.yzf.init({
    //     sign:
    //       "37ef9b97d27c02c120449fed1db4b86ad35cf1936152b2797ddedb7850f98be4b8d5d953e5965cead13bfe5093f550aff150a47a",
    //     uid: "",
    //     data: {
    //       c1: "",
    //       c2: "",
    //       c3: "",
    //       c4: "",
    //       c5: "",
    //     },
    //     selector: "",
    //     callback: function (type, data) {},
    //   });
    //window.yzf.close() 关闭1已打开的回话窗口
  },
};
</script>

<style lang="scss">
// html{
//   height: calc(100vh - 120px) !important;
//   margin-top: 120px !important;
// }
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
article,
aside,
audio,
canvas,
figure,
footer,
header,
mark,
menu,
nav,
section,
time,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
  display: block;
}
ul,
ol {
  list-style: none;
}
img {
  border: 0 none;
  vertical-align: top;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong,
em,
i {
  font-style: normal;
  font-weight: normal;
}
ins {
  text-decoration: underline;
}
del {
  text-decoration: line-through;
}
mark {
  background: none;
}
input::-ms-clear {
  display: none !important;
}
body {
  font: 12px/1.5 \5fae\8f6f\96c5\9ed1, \5b8b\4f53, "Hiragino Sans GB", STHeiti,
    "WenQuanYi Micro Hei", "Droid Sans Fallback", SimSun, sans-serif;
  background: #fff;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: underline;
}
body {
  margin: 0;
  padding: 0;
}
// div {
//   // box-sizing: border-box;
// }
#app {
  font-family: PingFang SC-粗体, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  // color: #2c3e50;
  margin-top: 48px;
  // min-width: 1200px;
  .banner {
    width: 100%;
    // height: 800px;
    // margin-top: 120px;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
.chat {
  position: fixed;
  bottom: 150px;
  right: 15px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 3px;
  background: #4979ff;
  color: #fff;
  img{
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }
}
</style>
